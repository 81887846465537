
import template from './template.html';

/**
 * This is an example knockout component class. Note the export section at the end of the file.
 * @kind component
 * @class SampleComponent
 * @description Sample Component that shows the name value
 */
class ComponentViewModel
{
	constructor(params)
	{
		this.params = params;

		this.data = ko_helper.safe_observable(params.data);
	}
}

export default {
	name: 'sample-component',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};
