// import css
import '../static/froala-editor/css/froala_editor.pkgd.min.css';

// import js
import components from './components/index.js';
import plugins from './plugins/index.js';
import FroalaEditor from '../static/froala-editor/js/froala_editor.pkgd.min.js';
import package_json from '../package.json';

// TODO move to own file
window.FroalaEditor = FroalaEditor;
window.Grape.froala = {
	key: 'aLF3c1A7C7D6A2B2A2G2xROKLJKYHROLDXDRH1e1YYGRe1Bg1G3I3A2A4D6A3F3E4D2G2==',
	toolbarButtons: [
			[
				'myButton',
				'fullscreen',
				'undo',
				'redo',
				'getPDF',
				'print'
			],
			[
				'bold',
				'italic',
				'underline',
				'textColor',
				'backgroundColor',
				'clearFormatting'
			],
			[
				'alignLeft',
				'alignCenter',
				'alignRight',
				'alignJustify'
			],
			[
				'formatOL',
				'formatUL',
				'indent',
				'outdent'
			],
			[
				'paragraphFormat'
			],
			[
				'fontFamily'
			],
			[
				'fontSize'
			],
			[
				'insertLink',
				'insertImage',
				'insertFile',
				'quote',
				'table',
				'html'
			]
		]
	};

(function() {

	window.Grape.modules.push(package_json);

	Grape.component.registerComponents(components);
	Grape.plugins.registerPlugins(plugins);
})();